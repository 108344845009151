import React from 'react'
import Image from 'next/image'
function WhyExcel({precisionAndProcess}:any) {

  return (
    <section className="sections">
        <div className="container">
            <div className="sections_title">
                <h2 data-aos="fade" data-aos-delay="100">{precisionAndProcess?.title}</h2>
                <p data-aos="fade" data-aos-delay="200">{precisionAndProcess?.description}</p>
            </div>

            <div className="row">
                {
                    precisionAndProcess?.processBoxes &&  precisionAndProcess?.processBoxes.map((pb:any,index:any)=>{
                        return(
                            <div key={index} className="col-lg-3 whywe_col col-md-6" data-aos="fade" data-aos-delay="100">
                                <div className="whywe_box">
                                
                                    <Image className="step_arrow" width={84} height={26} data-aos="fade" data-aos-delay="600" src="/images/step-arrow.svg"
                                    alt="step arrow" loading="lazy"/>
                                
                                        <Image className="step_arrow gif" width={84} height={26} data-aos="fade" data-aos-delay="600" src="/images/step_arrow.gif"
                                        alt="step arrow" loading="lazy"/>
                                    <div className="fit_img">
                                        <Image src={pb?.icon?.sourceUrl} width={90} height={90} alt={pb?.icon?.altText} loading="lazy"/>
                                    </div>
                                    <h3>{pb?.title}</h3>
                                    <p>{pb?.description}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default WhyExcel
